import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import './services.css';
import expert from '../assets/expert.jpg';
import study from '../assets/study.jpg';
import work from '../assets/work.jpg';
import allvisa from '../assets/allvisa.jpg';
import { Link as ScrollLink } from 'react-scroll';
import styled from 'styled-components';
const Services = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleNavigation = (path) => {
    navigate(path); // Navigate to the specified path
  };

  return (
    <div>
      {/* Services Section */}
      <section id="services" className="services-section">
        <div className="service-container">
          <div className="grid">
            {/* Image */}
            <div className="image-wrapper">
              <img src={allvisa} alt="Visa Service Image" className="image" />
            </div>
            {/* Text */}
            <div className="text-wrapper">
              <h2>Transforming Your Visa Experience</h2>
              <p>
                UK Visa Consulting Services offers professional support for multiple visa types, ensuring a smooth application process for you.
              </p>
              <ScrollMenuItem
                to="contact-form"
                smooth={true}
                duration={500}
              >
                Get Started
              </ScrollMenuItem>
            </div>
          </div>
        </div>
      </section>

      {/* Key Visa Services */}
      <section id="key-services" className="key-services-section">
        <div className="service-container text-center">
          <h2>Key Visa Services</h2>
          <p>Our services include personalized guidance for student, work, tourist, and family visas, ensuring precise processing.</p>
          <div className="buttons-grid">
            <button className="key-visa-button" onClick={() => handleNavigation('/work-visa')}>Work Visa</button>
            <button className="key-visa-button" onClick={() => handleNavigation('/student-visa')}>Study Visa</button>
            <button className="key-visa-button" onClick={() => handleNavigation('/business-visa')}>Business Visa</button>
            <button className="key-visa-button" onClick={() => handleNavigation('/innovator-visa')}>Innovator Visa</button>
            <button className="key-visa-button" onClick={() => handleNavigation('/sponshorship-visa')}>Sponsorship Visa</button>
            <button className="key-visa-button" onClick={() => handleNavigation('/hpi-visa')}>HPI Visa</button>
            <button className="key-visa-button" onClick={() => handleNavigation('/visit-visa')}>Visit Visa</button>
            <button className="key-visa-button" onClick={() => handleNavigation('/youth-mobility-visa')}>Youth Mobility Visa</button>
          </div>
        </div>
      </section>

      {/* Student Visa Services */}
      <section id="student-visa" className="visa-section reverse-grid">
        <div className="service-container grid">
          {/* Image */}
          <div className="image-wrapper">
            <img src={study} alt="Student Visa Image" className="image" />
          </div>
          {/* Text */}
          <div className="text-wrapper">
            <h2>Student Visa Services Simplified</h2>
            <p>
              We provide clear guidance through the student visa application process, helping you secure educational opportunities in the UK.
            </p>
            <button
              className="services-button"
              onClick={() => handleNavigation('/student-visa')}
            >
              Get Started
            </button>
          </div>
        </div>
      </section>

      {/* Work Visa Consultation Services */}
      <section id="work-visa" className="visa-section">
        <div className="service-container grid">
          {/* Image */}
          <div className="image-wrapper">
            <img src={work} alt="Work Visa Image" className="image" />
          </div>
          {/* Text */}
          <div className="text-wrapper">
            <h2>Work Visa Consultation Services</h2>
            <p>
              Specialized guidance for securing work visas, ensuring compliance and a smooth relocation process to the UK.
            </p>
            <button
              className="services-button"
              onClick={() => handleNavigation('/work-visa')}
            >
              Get Started
            </button>
          </div>
        </div>
      </section>

      {/* Partner Section */}
      <section id="partner" className="visa-section reverse-grid">
        <div className="service-container grid">
          {/* Image */}
          <div className="image-wrapper">
            <img src={expert} alt="Partner Image" className="image" />
          </div>
          {/* Text */}
          <div className="text-wrapper">
            <h2>Your Partner in Seamless Visa Applications</h2>
            <p>
              Dedicated to guiding you through the complexities of UK visa applications with personalized support for all your needs.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;

const ScrollMenuItem = styled(ScrollLink)`
padding: 10px 20px;
    background-color: #003366;
    color: #fff;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    align-self: flex-start;
    margin-top: 20px;
`;
