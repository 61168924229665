// GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* Font Import */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

  /* Global Reset and Typography */
  html, body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif; /* Apply the Poppins font globally */
    font-size: 16px;
    color: #333; /* Default text color */
    background-color: var(--base-color); /* Set background color from palette */
    line-height: 1.6; /* Improve readability */
  }
  /* Heading Reset and Styles */
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 600; /* Uniform font weight for all headings */
  }

  /* Anchor Tag Reset */
  a {
    text-decoration: none;
    color: var(--text-color-dark);
    transition: color 0.3s ease;

    &:hover {
      color: var(--accent-color); /* Accent color on hover */
    }
  }

  /* Button Styling */
  // button {
  //   cursor: pointer;
  //   background-color: var(--primary-color);
  //   border: none;
  //   color: var(--text-color-light);
  //   padding: 0.5rem 1rem;
  //   border-radius: 5px;
  //   transition: background-color 0.3s ease;

  //   &:hover {
  //     background-color: var(--secondary-color);
  //   }
  // }

  /* Define CSS Variables */
  :root {
    --primary-color: #96B6C5;
    --secondary-color: #ADC4CE;
    --accent-color: #EEE0C9;
    --base-color: #F1F0E8;
    --text-color-dark: #041A42;
    --text-color-light: #ffffff;
  }

  /* Utility Classes for Spacing and Layout */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default GlobalStyles;
