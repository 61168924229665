import React from 'react';
import styled from 'styled-components';
import Visa from '../assets/Visa.jpg';
import logo from '../assets/final.png';
import conulting from '../assets/consulting.webp';
import { Link as ScrollLink } from 'react-scroll';

const Hero = () => {
  return (
    <HeroSection>
      <Content>
        <Title>Your Gateway to UK Visas</Title>
        <Subtitle>Empowering your journey to the UK with expert visa consulting services tailored to your needs.</Subtitle>
        <ScrollMenuItem
                to="contact-form"
                smooth={true}
                duration={500}
              >
              Connect Today
              </ScrollMenuItem>
      </Content>

      {/* Image Section */}
      <ImageContainer>
        <ImageCard>
          <img src={Visa} alt="Expert Visa Assistance" />
          <ImageCaption>Expert Visa Assistance</ImageCaption>
        </ImageCard>
        <ImageCard>
          <img src={logo} alt="Effortless Navigation" />
          <ImageCaption>Effortless Navigation of Visa Applications</ImageCaption>
        </ImageCard>
        <ImageCard>
          <img src={conulting} alt="Comprehensive Visa Services" />
          <ImageCaption>Comprehensive Visa Services</ImageCaption>
        </ImageCard>
      </ImageContainer>
    </HeroSection>
  );
};

export default Hero;


const HeroSection = styled.section`
  height: auto;
  background-color: #041A42;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding-bottom: 2rem;  /* Adjusted padding for content and images */
`;

const Content = styled.div`
  margin-bottom: 2rem;  /* Margin to separate the hero content from images */
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;

const Button = styled.button`
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({ primary }) => (primary ? '#2979FF' : 'white')};
  color: ${({ primary }) => (primary ? 'white' : '#041A42')};
`;

/* Image Container for all images */
const ImageContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem;
`;

/* Each image card */
const ImageCard = styled.div`
  width: 300px;  /* Fixed width for images */
  text-align: center;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
  }
`;

const ImageCaption = styled.p`
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #b3b3b3;
`;

const ScrollMenuItem = styled(ScrollLink)`
  padding: 15px 30px;
  background: linear-gradient(90deg, #2979FF, #00D4FF);
  color: white;
  border-radius: 50px;  /* Rounded button for a modern look */
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  align-self: flex-start;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(41, 121, 255, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  /* Blinking & Pulsing Animation */
  animation: blink-pulse 2s infinite;

  &:hover {
    transform: scale(1.1);  /* Slight zoom on hover */
    box-shadow: 0px 6px 30px rgba(0, 212, 255, 0.8);  /* Stronger shadow on hover */
  }

  &:active {
    transform: scale(0.98);
  }

  @media (max-width: 768px) {
    font-size: 0.7rem;
    padding: 10px 25px;
  }

  /* Keyframe for Blinking and Pulsing */
  @keyframes blink-pulse {
    0%, 100% { opacity: 1; transform: scale(1); }
    50% { opacity: 0.6; transform: scale(1.05); }
  }

  /* Glowing Border Animation */
  &:before {
    content: "";
    position: absolute;
    inset: -5px;
    border-radius: 50px;
    padding: 2px;
    background: linear-gradient(45deg, #00D4FF, #2979FF, #FF4081, #FFD740);
    background-size: 300% 300%;
    animation: glow 3s linear infinite;
    z-index: -1;
  }

  @keyframes glow {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;


