import React from 'react';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Footer from './components/Footer';
import Services from './components/Services';
import './styles/styles.css';
import { Routes, Route } from 'react-router-dom';
import VisaDetails from './pages/VisaDetails';
import AboutUs from './pages/AboutUs'; // Import the AboutUs page
import TermsAndConditions from './pages/TermsAndConditions'; // Import the TermsAndConditions page
import PrivacyPolicy from './pages/PrivacyPolicy'; // Import the PrivacyPolicy page
import Contact from './pages/Contact'; // Import the Contact page
import JoinNow from './pages/JoinNow';
import ScrollToTop from './components/ScrollToTop';
import ContactForm from './components/ContactForm';
import GlobalStyles from './GlobalStyles';

function App() {
  return (
    <>
          <GlobalStyles /> {/* Apply global styles */}
      <ScrollToTop />
    <Navbar />
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />}/>
        <Route path="/join-now" element={<JoinNow />} />
        <Route path="/work-visa" element={<VisaDetails defaultVisa="skilledWorker" />} />
        <Route path="/student-visa" element={<VisaDetails defaultVisa="studentRoute" />} />
        <Route path="/business-visa" element={<VisaDetails defaultVisa="businessVisa" />} />
        <Route path="/innovator-visa" element={<VisaDetails defaultVisa="investorVisa" />} />
        <Route path="/sponshorship-visa" element={<VisaDetails defaultVisa="sponsorshipVisa" />} />
        <Route path="/hpi-visa" element={<VisaDetails defaultVisa="hpiVisa" />} />
        <Route path="/visit-visa" element={<VisaDetails defaultVisa="visitVisa" />} />
        <Route path="/youth-mobility-visa" element={<VisaDetails defaultVisa="youthMobilityVisa" />} />
        <Route path="/about-us" element={<AboutUs />} /> {/* New route */}
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contact-form" element={<ContactForm />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
