// src/pages/JoinNow.js
import React from 'react';
import styled from 'styled-components';
import { FaTools } from 'react-icons/fa'; // Using FontAwesome for an icon.

const JoinNow = () => {
  return (
    <JoinNowContainer>
      <IconContainer>
        <FaTools size={80} color="#2979FF" />
      </IconContainer>
      <Message>We are currently working on this. Thanks for visiting!</Message>
      <SubMessage>Come back soon for exciting updates.</SubMessage>
    </JoinNowContainer>
  );
};

export default JoinNow;

const JoinNowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px; /* Add padding for better spacing on small screens */
  background-color: #f0f4f8;

  @media (max-width: 768px) {
    height: auto; /* Ensure content fits well on smaller screens */
    padding: 40px 10px;
  }
`;

const IconContainer = styled.div`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const Message = styled.h1`
  font-size: 2rem;
  color: #041A42;
  margin-bottom: 10px;
  text-align: center; /* Center text for better readability on small screens */

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const SubMessage = styled.p`
  font-size: 1.2rem;
  color: #555;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;
