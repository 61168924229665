// Contact.js
import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-container">
      <h1 className="contact-title">Contact Us</h1>
      <div className="contact-info">
        <p className="contact-text">
          <strong>Phone:</strong> <a href="tel:+1234567890">+1 234 567 890</a>
        </p>
        <p className="contact-text">
          <strong>Email:</strong> <a href="mailto:info@example.com">info@example.com</a>
        </p>
      </div>
      <div className="contact-highlight">
        We're here to help! Reach out to us with any queries or concerns, and we'll get back to you promptly.
      </div>
    </div>
  );
};

export default Contact;
