import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import Modal from 'react-modal';
import { useNavigate, useLocation } from 'react-router-dom'; // Import hooks
import './contactForm.css';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#e9ecef',
    borderRadius: '10px',
    padding: '30px',
    textAlign: 'center',
    width: '500px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

const ContactForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    whatsAppNumber: '',
    message: '',
  });

  const [isSameAsWhatsApp, setIsSameAsWhatsApp] = useState(true);
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    const savedData = sessionStorage.getItem('contactFormData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }

    // Detect if returning from privacy policy and scroll to contact form
    const fromPrivacy = sessionStorage.getItem('fromPrivacy');
    if (fromPrivacy) {
      sessionStorage.removeItem('fromPrivacy');
      navigate('/', { replace: true });
      setTimeout(() => {
        document.getElementById('contact-form').scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [location, navigate]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleCheckboxChange = (e) => {
    setIsPrivacyPolicyChecked(e.target.checked);
  };

  const handleToggleChange = () => {
    setIsSameAsWhatsApp((prevState) => !prevState);
    if (!isSameAsWhatsApp) {
      setFormData({ ...formData, whatsAppNumber: '' });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      whatsAppNumber: isSameAsWhatsApp ? formData.phone : formData.whatsAppNumber,
      message: formData.message,
    };

    emailjs
      .send('service_aytp7qq', 'template_v1wu00e', templateParams, 'yKUnyXFKpTKH0_DEQ')
      .then(
        (response) => {
          setStatusMessage('Message sent successfully!');
          setModalIsOpen(true);
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            whatsAppNumber: '',
            message: '',
          });
          setIsPrivacyPolicyChecked(false);
          sessionStorage.removeItem('contactFormData'); // Clear saved data
        },
        (error) => {
          setStatusMessage('Failed to send the message. Please try again.');
          setModalIsOpen(true);
        }
      );
  };

  const handlePrivacyPolicyClick = () => {
    sessionStorage.setItem('contactFormData', JSON.stringify(formData));
    sessionStorage.setItem('fromPrivacy', 'true');
    navigate('/privacy-policy');
  };

  return (
    <section id="contact-form" className="contact-section">
      <div className="container grid">
        <div className="info-wrapper">
          <h1>Connect with UK Visa Consulting Services</h1>
          <ul>
            <li>✔ Personalized Consulting for Your Visa Needs.</li>
            <li>✔ Timely updates and guidance throughout the process for a seamless experience.</li>
            <li>✔ Expert advice and tailored solutions for every visa type you require.</li>
            <li>✔ Detailed information and services covering various visas for various purposes.</li>
          </ul>
        </div>

        <div className="form-wrapper">
          <h2>Get in Touch Today</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="firstName">First name</label>
              <input
                type="text"
                id="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="First Name"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last name</label>
              <input
                type="text"
                id="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Last Name"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="name@domain.com"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone number</label>
              <input
                type="tel"
                id="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Phone Number"
                required
              />
            </div>

            <div className="form-group toggle-wrapper">
              <label htmlFor="whatsapp-toggle">Is phone number the same as WhatsApp?</label>
              <label className="switch">
                <input
                  type="checkbox"
                  id="whatsapp-toggle"
                  checked={isSameAsWhatsApp}
                  onChange={handleToggleChange}
                />
                <span className="slider round"></span>
              </label>
            </div>

            {!isSameAsWhatsApp && (
              <div className="form-group">
                <label htmlFor="whatsAppNumber">WhatsApp Number</label>
                <input
                  type="tel"
                  id="whatsAppNumber"
                  value={formData.whatsAppNumber}
                  onChange={handleInputChange}
                  placeholder="WhatsApp Number"
                />
              </div>
            )}

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Your message"
                required
              ></textarea>
            </div>

            <div className="form-group checkbox-wrapper">
              <input
                type="checkbox"
                id="privacy-policy"
                checked={isPrivacyPolicyChecked}
                onChange={handleCheckboxChange}
                required
              />
              <label htmlFor="privacy-policy">
                You agree to our <button className="privacy-policy" onClick={handlePrivacyPolicyClick}>privacy policy</button>
              </label>
            </div>

            <button type="submit" className="cta-button">Send message</button>
          </form>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customModalStyles}
      >
        <h2>{statusMessage}</h2>
        <button className="modal-close-button" onClick={() => setModalIsOpen(false)}>
          Close
        </button>
      </Modal>
    </section>
  );
};

export default ContactForm;
