import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter
import App from './App'; // Import your main App component

// Create a root element
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Render the app wrapped in Router
root.render(
  <React.StrictMode>
    <Router> {/* Wrap App inside Router */}
      <App />
    </Router>
  </React.StrictMode>
);
