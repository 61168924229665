import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS file

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1 className="privacy-title">Privacy Policy</h1>

      <h2 className="section-heading">1. Introduction</h2>
      <p className="privacy-paragraph">
        This Privacy Policy outlines how INNIT Consultancy Services collects, uses, and protects your personal data. By using our website and services, you agree to the collection and use of your information in accordance with this policy.
      </p>

      <h2 className="section-heading">2. Information We Collect</h2>
      <ul className="privacy-list">
        <li>Personal Identification Data: Name, email address, phone number, etc.</li>
        <li>Application Information: Data required for visa applications.</li>
        <li>Browsing Data: IP address, browser type, and usage patterns.</li>
        <li>Cookies: To enhance your browsing experience.</li>
      </ul>

      <h2 className="section-heading">3. How We Use Your Data</h2>
      <ul className="privacy-list">
        <li>To process visa applications and related services.</li>
        <li>To contact you regarding your application or our services.</li>
        <li>To improve our website and customer experience.</li>
        <li>To comply with legal obligations and regulatory requirements.</li>
      </ul>

      <h2 className="section-heading">4. Data Sharing and Disclosure</h2>
      <p className="privacy-paragraph">
        We do not sell your data to third parties. However, we may share your data with:
      </p>
      <ul className="privacy-list">
        <li>Government authorities for visa applications.</li>
        <li>Third-party service providers (e.g., payment gateways).</li>
        <li>Legal authorities if required by law.</li>
      </ul>

      <h2 className="section-heading">5. Data Security</h2>
      <p className="privacy-paragraph">
        We implement appropriate security measures to protect your data. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.
      </p>

      <h2 className="section-heading">6. Your Data Rights</h2>
      <ul className="privacy-list">
        <li>Right to Access: You can request a copy of your personal data.</li>
        <li>Right to Rectification: You can request corrections to inaccurate data.</li>
        <li>Right to Erasure: You can request the deletion of your data, subject to legal obligations.</li>
        <li>Right to Object: You can object to certain types of data processing.</li>
      </ul>

      <h2 className="section-heading">7. Cookies</h2>
      <p className="privacy-paragraph">
        Our website uses cookies to enhance your experience. You can manage your cookie preferences in your browser settings.
      </p>

      <h2 className="section-heading">8. Changes to this Policy</h2>
      <p className="privacy-paragraph">
        We reserve the right to update this Privacy Policy. Any significant changes will be communicated via our website.
      </p>

      
    </div>
  );
};

export default PrivacyPolicy;
