// Navbar.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { FaTimes, FaBars } from 'react-icons/fa';
import newLogo from '../assets/new.PNG';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const handleBackClick = () => {
    const fromPrivacy = sessionStorage.getItem('fromPrivacy');
    if (fromPrivacy) {
      sessionStorage.removeItem('fromPrivacy');
      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  const handleServicesClick = () => {
    if (location.pathname !== '/') {
      navigate('/'); 
      setTimeout(() => {
        const element = document.getElementById('key-services');
        element?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } else {
      const element = document.getElementById('key-services');
      element?.scrollIntoView({ behavior: 'smooth' });
    }
    setIsOpen(false); // Close the menu after click
  };

  // Close menu on route change
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const isHomePage = location.pathname === '/';

  return (
    <Nav>
      {!isHomePage && <BackButton onClick={handleBackClick}>←</BackButton>}

      <LogoContainer isHomePage={isHomePage} isOpen={isOpen}>
        <Logo to="/" onClick={() => setIsOpen(false)}>
          <img src={newLogo} alt="Brand Logo" />
        </Logo>
      </LogoContainer>

      <DesktopMenu>
        <MenuItem to="/about-us">About</MenuItem>
        <StyledButton onClick={handleServicesClick}>Services</StyledButton>
        <MenuItem to="/contact">Contact</MenuItem>
        <MenuItem to="/join-now">Join Now</MenuItem>
      </DesktopMenu>

      <Hamburger onClick={toggleMenu}>
        {isOpen ? <FaTimes size={24} color="white" /> : <FaBars size={24} color="white" />}
      </Hamburger>

      <MobileMenu isOpen={isOpen}>
        <Logo to="/" onClick={() => setIsOpen(false)}>
          <img src={newLogo} alt="Brand Logo" />
        </Logo>
        <MenuItem to="/about-us" onClick={() => setIsOpen(false)}>About</MenuItem>
        <StyledButton onClick={handleServicesClick}>Services</StyledButton>
        <MenuItem to="/contact" onClick={() => setIsOpen(false)}>Contact</MenuItem>
        <MenuItem to="/join-now" onClick={() => setIsOpen(false)}>Join Now</MenuItem>
      </MobileMenu>
    </Nav>
  );
};

export default Navbar;

// Styled Components
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #041A42;
  color: white;
  position: relative;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: ${({ isHomePage, isOpen }) =>
    isOpen || !isHomePage ? 'center' : 'flex-start'};
  flex: 1;
  transition: justify-content 0.3s ease;
`;

const Logo = styled(RouterLink)`
  display: flex;
  align-items: center;

  img {
    height: ${({ isOpen }) => (isOpen ? '59px' : '90px')};
    width: auto;
    transition: height 0.3s ease;

    @media (max-width: 768px) {
      height: 59px;
    }
  }
`;

const DesktopMenu = styled.div`
  display: flex;
  gap: 1.5rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MenuItem = styled(RouterLink)`
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  margin: 0.5rem 0;
  text-align: left;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #002244;
  }
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  margin: 0.5rem 0;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #002244;
  }

  &:focus {
    outline: none;
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 20px;
  z-index: 1001;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #041A42;
  z-index: 1000;
  padding-top: 2rem;
`;

const BackButton = styled.button`
  position: absolute;
  left: 15px;
  top: 16px;
  background: none;
  border: none;
  font-size: 2.5rem;
  color: white;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
