import React, { useState, useEffect, useRef } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import BusinessIcon from "@mui/icons-material/Business";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"; // Investor Visa
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom"; // Sponsorship Visa
import PublicIcon from "@mui/icons-material/Public"; // Visit Visa
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch"; // HPI Visa
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun"; // Youth Mobility Visa
import './VisaDetails.css';  // Keep your existing styles
import { useNavigate } from 'react-router-dom';
 
 
const VisaDetails = ({ defaultVisa }) => {
  const [expandedVisa, setExpandedVisa] = useState(null);
  const navigate = useNavigate();
 
  // Create refs for all visa sections
  const visaIcons = {
    skilledWorker: <WorkIcon />,
    studentRoute: <SchoolIcon />,
    businessVisa: <BusinessIcon />,
    investorVisa: <AttachMoneyIcon />,
    sponsorshipVisa: <FamilyRestroomIcon />,
    hpiVisa: <RocketLaunchIcon />,
    visitVisa: <PublicIcon />,
    youthMobilityVisa: <DirectionsRunIcon />,
  };
 
  const visaRefs = {
    skilledWorker: useRef(null),
    studentRoute: useRef(null),
    businessVisa: useRef(null),
    investorVisa: useRef(null),
    sponsorshipVisa: useRef(null),
    hpiVisa: useRef(null),
    visitVisa: useRef(null),
    youthMobilityVisa: useRef(null),
  };
 
  const toggleVisa = (visa) => {
    setExpandedVisa(expandedVisa === visa ? null : visa);
  };
 
  const VisaParagraph = ({ content }) => {
    return <div className="visa-paragraph">{content}</div>;
  };
 
  useEffect(() => {
    if (defaultVisa) {
      setExpandedVisa(defaultVisa);
      // Small timeout to allow DOM to render before scrolling
      setTimeout(() => scrollToVisa(defaultVisa), 100);
    }
  }, [defaultVisa]);
 
  const scrollToVisa = (visa) => {
    const element = visaRefs[visa]?.current;
    if (element) {
      const yOffset = -80; // Offset for any fixed header
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
 
  // Visa details object for all visas
  const visaDetails = {
    skilledWorker: {
      title: "Why Choose the UK for Your Next Career Move?",
      icon: visaIcons.skilledWorker,
      details: (
        <>
          <VisaParagraph
            content={
              "The UK offers a thriving environment for skilled professionals, boasting the world's fifth-largest economy and a rich array of industries. The country is home to prestigious universities with top global rankings, ensuring a knowledge-driven workforce."
            }
          />
          <VisaParagraph
            content={
              "For those aiming to build a prosperous career in a global setting, the UK Skilled Worker Visa provides a structured path to success."
            }
          />
          <ul className="benefits-list">
            <li><strong>Family inclusion:</strong> Dependents, including spouses and children, can accompany the visa holder to the UK.</li>
            <li><strong>Spouses' work rights:</strong> Spouses are allowed to work while on this visa.</li>
            <li><strong>No immigration caps:</strong> No limit on the number of individuals who can move to the UK under this visa.</li>
            <li><strong>Lower salary thresholds:</strong> The minimum salary requirement is £25,600.</li>
            <li><strong>Fast-track for healthcare:</strong> Expedited visa processing for doctors, nurses, and healthcare professionals.</li>
          </ul>
          <h3>How INNIT Can Help You with Your UK Work Visa</h3>
          <VisaParagraph
            content={
            "At INNIT, we understand the complexities of the UK work visa process and are here to assist you at every step. Our expert services include:"
            }
            />
          <ul className="benefits-list">
            <li><strong>Comprehensive document checklists:</strong> Ensuring all necessary documentation is prepared and accurate.</li>
            <li><strong>Application processing and filing:</strong> From filling out forms to submitting your application, we handle the details to ensure a smooth process.</li>
            <li><strong>Ongoing support:</strong> Our team provides updates and follows up on your application, ensuring timely processing.</li>
          </ul>
          <VisaParagraph
            content={
            "Start your journey in the UK with confidence by partnering with INNIT for your visa needs. We’ll guide you through the entire process, allowing you to focus on building your global business presence."
            }
          />
        <Button 
  variant="contained" 
  color="primary" 
  style={{ marginTop: '10px' }}
  onClick={() => navigate('/contact-form')}
>
  Apply Now
</Button>
        </>
      ),
    },
    studentRoute: {
      title: "Why Apply for a UK Student Visa?",
      icon: visaIcons.studentRoute,
      details: (
        <>
                    <VisaParagraph content="The UK offers an exceptional educational environment, with its universities consistently ranking among the top in the world. Applying for a UK Student Visa can be a transformative step toward building a successful future." />
          <ul className="benefits-list">
            <li><strong>90 QS World Ranking Universities:</strong> The UK is home to prestigious universities recognized globally.</li>
            <li><strong>96% Visa Success Rate:</strong> The high approval rate ensures a smooth process for international students.</li>
            <li><strong>Post-Study Work Visa:</strong> A 2-year post-study work visa allows graduates to gain valuable work experience in the UK.</li>
            <li><strong>Affordable Education:</strong> Tuition fees range from £10,000 to £46,000 per year, offering a variety of options.</li>
            <li><strong>Scholarships Available:</strong> Scholarships between £1,000 and £6,000 per year help reduce education costs.</li>
            <li><strong>Quick Visa Processing:</strong> Students can expect to receive their visas in 3 to 6 weeks, ensuring minimal delays.</li>
          </ul>
         
         
            <VisaParagraph content="The UK stands as a top destination for international students, attracting over 600,000 students annually. Home to world-renowned universities, the country provides education that is globally recognized. Degrees from UK institutions are highly respected worldwide." />          
          <h3>How INNIT Can Help You with Your UK Student Visa</h3>
          <VisaParagraph content=" At INNIT, we understand the complexities of the UK student visa process and are here to assist you at every step. Our expert services include:" />
 
          <ul className="benefits-list">
            <li><strong>Comprehensive document checklists:</strong> Ensuring all necessary documentation is prepared and accurate.</li>
            <li><strong>Application processing and filing:</strong> From filling out forms to submitting your application, we handle the details to ensure a smooth process.</li>
            <li><strong>Ongoing support:</strong> Our team provides updates and follows up on your application, ensuring timely processing.</li>
          </ul>
          <VisaParagraph
            content={
            "Start your journey in the UK with confidence by partnering with INNIT for your visa needs. We’ll guide you through the entire process, allowing you to focus on building your global business presence."
            }
          />
          <Button 
  variant="contained" 
  color="primary" 
  style={{ marginTop: '10px' }}
  onClick={() => navigate('/contact-form')}
>
  Apply Now
</Button>
        </>
      ),
    },
    businessVisa: {
      title: "UK Business Visa: A Pathway for Global Entrepreneurs",
      icon: visaIcons.businessVisa,
      details: (
        <>
                    <VisaParagraph content="The United Kingdom is a global economic hub, making it a top destination for business leaders and entrepreneurs from across the world. For individuals looking to visit the UK for business purposes, the Standard Visitor Visa is the most common route." />
          <h3>Overview of the UK Business Visa</h3>
          <VisaParagraph content="The UK Business Visa enables short-term stays for business activities, typically allowing holders to remain in the UK for up to six months per visit." />
          <h3>Key Activities Permitted Under the UK Business Visa</h3>
          <ul className="benefits-list">
            <li><strong>Attending conferences or meetings:</strong> Ideal for networking and engaging with industry experts.</li>
            <li><strong>Participating in sports or professional events:</strong> For athletes, coaches, or trainers attending UK-based activities.</li>
            <li><strong>Seeking investment opportunities:</strong> Entrepreneurs can explore funding for new ventures, joint partnerships, or business expansion.</li>
            <li><strong>Corporate exchanges:</strong> Employees from international companies can share skills, knowledge, or receive training on UK-specific methods or internal projects.</li>
            <li><strong>Conducting audits:</strong> Internal auditors may perform financial or regulatory audits for a UK branch of a company from abroad.</li>
          </ul>
 
          <h3>Documents Required for the UK Business Visa Application</h3>
          <ul className="benefits-list">
            <li><strong>Personal and financial details:</strong> To prove your ability to support yourself during the visit.</li>
            <li><strong>Travel history and itinerary:</strong> Information about your planned activities and prior visits to the UK or other countries.</li>
            <li><strong>Proof of business activities:</strong> Documents detailing your business engagements in the UK, such as invitations to conferences, meetings, or funding agreements.</li>
            <li><strong>Financial proof:</strong> Evidence that you can cover your stay and travel expenses, including return or onward journey.</li>
            <li><strong>Commitment to leaving the UK:</strong> Proof that you will exit the UK upon completing your business activities.</li>
          </ul>
 
          <h3>Eligibility Criteria for the UK Business Visa</h3>
          <ul className="benefits-list">
            <li><strong>Age requirement:</strong> Must be 18 years or older.</li>
            <li><strong>Financial self-sufficiency:</strong> Demonstrate the ability to support yourself and dependents during your stay.</li>
            <li><strong>Health requirements:</strong> Meet the basic health standards set by UK authorities.</li>
            <li><strong>Legitimate business purpose:</strong> Have a formal invitation from a recognized UK company or organization for business purposes.</li>
          </ul>
 
          <h3>Fees and Duration</h3>
          <VisaParagraph content="The cost of applying for the standard 6-month UK Business Visa is £95. For those seeking longer-term visas (2, 5, or 10 years), the fees increase accordingly. However, regardless of visa length, each stay is capped at six months. This visa offers great flexibility, allowing frequent business visits without the need for frequent re-application." />
 
 
          <h3>How INNIT Can Help You with Your UK Business Visa</h3>
          <VisaParagraph content=" At INNIT, we understand the complexities of the UK business visa process and are here to assist you at every step. Our expert services include:" />
 
         
          <ul className="benefits-list">
            <li><strong>Comprehensive document checklists:</strong> Ensuring all necessary documentation is prepared and accurate.</li>
            <li><strong>Application processing and filing:</strong> From filling out forms to submitting your application, we handle the details to ensure a smooth process.</li>
            <li><strong>Ongoing support:</strong> Our team provides updates and follows up on your application, ensuring timely processing.</li>
          </ul>
          <VisaParagraph
            content={
            "Start your journey in the UK with confidence by partnering with INNIT for your visa needs. We’ll guide you through the entire process, allowing you to focus on building your global business presence."
            }
          />
     <Button 
  variant="contained" 
  color="primary" 
  style={{ marginTop: '10px' }}
  onClick={() => navigate('/contact-form')}
>
  Apply Now
</Button>
        </>
      ),
    },
    investorVisa: {
      title: "UK Innovator Founder Visa: A Path to Investment and Settlement",
      icon: visaIcons.investorVisa,
      details: (
        <>
  <VisaParagraph content="The UK Innovator Founder Visa is designed for experienced entrepreneurs and investors looking to establish an innovative business in the UK. This visa offers a unique opportunity for entrepreneurs to live, work, and ultimately settle in the UK. With a focus on innovative ideas, this visa category supports business leaders who want to contribute to the UK’s thriving economy. Applicants can bring their families and settle permanently after 5 years, making it an attractive option for global entrepreneurs."/>
          <h3>Benefits of the UK Innovator Founder Visa</h3>
          <ul className="benefits-list">
            <li><strong>No Minimum Investment Requirement:</strong> Unlike other visa categories, the Innovator Founder Visa does not have a set minimum investment. The required investment will depend on the business concept and its potential.</li>
            <li><strong>Live and Work in the UK:</strong> You and your family can live in the UK for up to 3 years INNITially, with the option to extend the visa for another 2 years.</li>
            <li><strong>Access to UK Healthcare and Education:</strong> As a visa holder, you and your family will have access to the UK’s National Health Service (NHS) and public education.</li>
            <li><strong>Path to Permanent Residency:</strong> After 5 years of residence, you can apply for IndefINNITe Leave to Remain (ILR), allowing you to stay in the UK permanently.</li>
            <li><strong>Quick and Streamlined Process:</strong> The Innovator Founder Visa is designed to be processed efficiently, with straightforward steps for those meeting the eligibility criteria.</li>
          </ul>
 
          <h3>Steps to Apply for the UK Innovator Founder Visa</h3>
          <ol>
            <li>Complete the Online Application: Submit your visa application through the official UK visa portal.</li>
            <li>Gather Required Documents: Provide all necessary documents in the required formats (JPG, PNG, PDF, or JPEG).</li>
            <li>Pay Fees and Healthcare Surcharge: Submit payment for the visa application and the Immigration Health Surcharge (IHS).</li>
            <li>Submit the Application: Complete and submit the application online.</li>
            <li>Attend Visa Appointment: Schedule and attend an appointment at the visa application center for biometrics.</li>
            <li>Wait for Processing: Your visa application will be processed, and you will be notified of the decision.</li>
          </ol>
 
          <h3>Eligibility Criteria for the UK Innovator Founder Visa</h3>
          <ul className="benefits-list">
            <li><strong>Innovative Business Idea:</strong> Your business idea must be innovative, scalable, and endorsed by an approved endorsing body.</li>
            <li><strong>Funds for Investment:</strong> Although there is no fixed minimum investment, you must have adequate funds to start and sustain the business.</li>
            <li><strong>Point-Based System:</strong> You must meet the points-based system requirements, which consider investment funds, English language proficiency, and maintenance funds.</li>
            <li><strong>Non-EEA Applicant:</strong> This visa is for non-European Economic Area (EEA) and non-Swiss nationals.</li>
            <li><strong>Personal Requirements:</strong> You must be in good health, have no criminal record, and be able to demonstrate your business experience.</li>
          </ul>
 
          <h3>Why Choose the UK Innovator Founder Visa?</h3>
          <VisaParagraph content="This visa provides an exceptional opportunity for entrepreneurs to launch and grow innovative businesses in one of the world’s leading economies. With access to the UK’s diverse and robust market, strong legal framework, and global networking opportunities, the Innovator Founder Visa allows business leaders to thrive and potentially settle in the UK."/>
          <h3>How INNIT Can Help You with Your UK Innovator Visa</h3>
          <VisaParagraph content=" At INNIT, we understand the complexities of the UK Innovator visa process and are here to assist you at every step. Our expert services include:" />
 
          <ul className="benefits-list">
            <li><strong>Comprehensive document checklists:</strong> Ensuring all necessary documentation is prepared and accurate.</li>
            <li><strong>Application processing and filing:</strong> From filling out forms to submitting your application, we handle the details to ensure a smooth process.</li>
            <li><strong>Ongoing support:</strong> Our team provides updates and follows up on your application, ensuring timely processing.</li>
          </ul>
          <VisaParagraph
            content={
            "Start your journey in the UK with confidence by partnering with INNIT for your visa needs. We’ll guide you through the entire process, allowing you to focus on building your global business presence."
            }
          />
     <Button 
  variant="contained" 
  color="primary" 
  style={{ marginTop: '10px' }}
  onClick={() => navigate('/contact-form')}
>
  Apply Now
</Button>
        </>
      ),
    },
    sponsorshipVisa: {
      title: "UK Sponsorship Visa",
      icon: visaIcons.sponsorshipVisa,
      details: (
        <>
          <h3>What is a UK Sponsorship Licence?</h3>
          <VisaParagraph content="A UK Sponsorship Licence allows employers to legally hire non-UK workers.."/>
          <h4>Types of Sponsorship Licences</h4>
          <ul>
            <li>Long-Term Employment Sponsor Licence</li>
            <li>Temporary Employment Sponsor Licence</li>
          </ul>
          <h4>Benefits of Sponsorship Licence</h4>
          <VisaParagraph content="Provides access to global talent and ensures compliance with immigration laws."/>
          <h3>How INNIT Can Help You with Your UK Sponsorship Visa</h3>
          <VisaParagraph content=" At INNIT, we understand the complexities of the UK Sponsorship visa process and are here to assist you at every step. Our expert services include:" />
 
          <ul className="benefits-list">
            <li><strong>Comprehensive document checklists:</strong> Ensuring all necessary documentation is prepared and accurate.</li>
            <li><strong>Application processing and filing:</strong> From filling out forms to submitting your application, we handle the details to ensure a smooth process.</li>
            <li><strong>Ongoing support:</strong> Our team provides updates and follows up on your application, ensuring timely processing.</li>
          </ul>
          <VisaParagraph
            content={
            "Start your journey in the UK with confidence by partnering with INNIT for your visa needs. We’ll guide you through the entire process, allowing you to focus on building your global business presence."
            }
          />
     <Button 
  variant="contained" 
  color="primary" 
  style={{ marginTop: '10px' }}
  onClick={() => navigate('/contact-form')}
>
  Apply Now
</Button>
        </>
      ),
    },
    hpiVisa: {
      title: "High Potential Individual (HPI) Visa",
      icon: visaIcons.hpiVisa,
      details: (
        <>
          <h3>The HPI Visa: Unlocking Opportunities for Global Graduates</h3>
          <VisaParagraph content="No job offer needed; start your career in the UK easily."/>
          <h4>Eligibility Criteria</h4>
          <ul>
            <li>Age Requirement: 18+</li>
            <li>Recent Graduate from a Top University</li>
            <li>English Language Proficiency</li>
          </ul>
          <h4>Benefits of the HPI Visa</h4>
          <VisaParagraph content="Flexible work options and pathways to switch to other visa types."/>
          <h3>How INNIT Can Help You with Your UK HPI Visa</h3>
          <VisaParagraph content=" At INNIT, we understand the complexities of the UK HPI visa process and are here to assist you at every step. Our expert services include:" />
 
          <ul className="benefits-list">
            <li><strong>Comprehensive document checklists:</strong> Ensuring all necessary documentation is prepared and accurate.</li>
            <li><strong>Application processing and filing:</strong> From filling out forms to submitting your application, we handle the details to ensure a smooth process.</li>
            <li><strong>Ongoing support:</strong> Our team provides updates and follows up on your application, ensuring timely processing.</li>
          </ul>
          <VisaParagraph
            content={
            "Start your journey in the UK with confidence by partnering with INNIT for your visa needs. We’ll guide you through the entire process, allowing you to focus on building your global business presence."
            }
          />
     <Button 
  variant="contained" 
  color="primary" 
  style={{ marginTop: '10px' }}
  onClick={() => navigate('/contact-form')}
>
  Apply Now
</Button>
        </>
      ),
    },
    visitVisa: {
      title: "UK Visit Visa",
      icon: visaIcons.visitVisa,
      details: (
        <>
          <h3>Explore the UK with a Visit Visa</h3>
          <VisaParagraph content="Enjoy breathtaking landscapes, cultural events, and more."/>
          <h4>Types of UK Visit Visas</h4>
          <ul>
            <li>Standard Visitor Visa</li>
            <li>Marriage Visitor Visa</li>
            <li>Medical Visitor Visa</li>
          </ul>
          <h4>Application Process</h4>
          <VisaParagraph content="Prepare documents, apply online, and attend a biometric appointment."/>
          <h3>How INNIT Can Help You with Your UK Visit Visa</h3>
          <VisaParagraph content=" At INNIT, we understand the complexities of the UK Visit visa process and are here to assist you at every step. Our expert services include:" />
 
          <ul className="benefits-list">
            <li><strong>Comprehensive document checklists:</strong> Ensuring all necessary documentation is prepared and accurate.</li>
            <li><strong>Application processing and filing:</strong> From filling out forms to submitting your application, we handle the details to ensure a smooth process.</li>
            <li><strong>Ongoing support:</strong> Our team provides updates and follows up on your application, ensuring timely processing.</li>
          </ul>
          <VisaParagraph
            content={
            "Start your journey in the UK with confidence by partnering with INNIT for your visa needs. We’ll guide you through the entire process, allowing you to focus on building your global business presence."
            }
          />
     <Button 
  variant="contained" 
  color="primary" 
  style={{ marginTop: '10px' }}
  onClick={() => navigate('/contact-form')}
>
  Apply Now
</Button>
        </>
      ),
    },
    youthMobilityVisa: {
      title: "Youth Mobility Visa",
      icon: visaIcons.youthMobilityVisa,
      details: (
        <>
          <h3>Unlock Career Opportunities with the Youth Mobility Visa</h3>
          <VisaParagraph
            content={
          "Live and work in the UK for up to 2 years."
            }
            />
          <h4>Eligibility</h4>
          <ul>
            <li>Age: 18-30</li>
            <li>Financial Requirement: £2,530 in savings</li>
          </ul>
          <h4>Benefits of the Youth Mobility Visa</h4>
          <VisaParagraph
            content={
          "Freedom to work, study, or start a business in the UK."
            }
            />
          <h3>How INNIT Can Help You with Your UK Youth Mobility Visa</h3>
          <VisaParagraph
            content={
            "At INNIT, we understand the complexities of the UK Youth Mobility visa process and are here to assist you at every step. Our expert services include:"
            }
            />
          <ul className="benefits-list">
            <li><strong>Comprehensive document checklists:</strong> Ensuring all necessary documentation is prepared and accurate.</li>
            <li><strong>Application processing and filing:</strong> From filling out forms to submitting your application, we handle the details to ensure a smooth process.</li>
            <li><strong>Ongoing support:</strong> Our team provides updates and follows up on your application, ensuring timely processing.</li>
          </ul>
          <VisaParagraph
            content={
            "Start your journey in the UK with confidence by partnering with INNIT for your visa needs. We’ll guide you through the entire process, allowing you to focus on building your global business presence."
            }
          />
   <Button 
  variant="contained" 
  color="primary" 
  style={{ marginTop: '10px' }}
  onClick={() => navigate('/contact-form')}
>
  Apply Now
</Button>
        </>
      ),
    },
  };
 
  return (
    <div className="visa-container">
      <div className="hero-section">
        <h1>Explore Your Visa Options</h1>
        <VisaParagraph
            content={
        "Discover the right visa pathway for your journey to the UK."
        }
        />
      </div>
 
      <section className="pathways-section">
        {Object.keys(visaDetails).map((visa) => (
          <Accordion
            key={visa}
            expanded={expandedVisa === visa}
            onChange={() => toggleVisa(visa)}
            ref={visaRefs[visa]}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">
                {visaDetails[visa].icon} {visaDetails[visa].title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {visaDetails[visa].details}
            </AccordionDetails>
          </Accordion>
        ))}
      </section>
    </div>
  );
};
 
export default VisaDetails;