import React from 'react';
import './TermsAndConditions.css'; // Import the CSS

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1 className="terms-title">Terms and Conditions</h1>

      <h2 className="section-heading">1. Introduction</h2>
      <p className="terms-paragraph">
        Welcome to INNIT Consultancy Services. These terms and conditions govern your use of our services and website. By engaging with our services or browsing our website, you agree to be bound by these terms and conditions.
      </p>

      <h2 className="section-heading">2. Services Overview</h2>
      <p className="terms-paragraph">
        INNIT Consultancy provides visa advisory services, application assistance, and immigration consulting. We do not guarantee visa approval, as the final decision lies solely with the relevant UK authorities.
      </p>

      <h2 className="section-heading">3. Client Responsibilities</h2>
      <ul className="terms-list">
        <li>Provide accurate and complete information required for the visa application process.</li>
        <li>Submit necessary documentation within the specified timeframe.</li>
        <li>Comply with the UK immigration laws and policies.</li>
        <li>Notify us of any changes to your circumstances that may impact your application.</li>
      </ul>

      <h2 className="section-heading">4. Fees and Payments</h2>
      <p className="terms-paragraph">
        All fees are disclosed upfront and are non-refundable. Payment must be made in full before any application is processed. Any additional government fees or third-party charges are the client's responsibility.
      </p>

      <h2 className="section-heading">5. Limitation of Liability</h2>
      <p className="terms-paragraph">
        INNIT Consultancy will not be liable for any loss, delay, or rejection of an application due to incorrect or incomplete information provided by the client or changes in immigration policies. Our liability is limited to the fees paid for the consulting services.
      </p>

      <h2 className="section-heading">6. Refund Policy</h2>
      <p className="terms-paragraph">
        Refunds are not provided unless explicitly agreed upon. However, if the service cannot be rendered due to an unforeseen issue on our part, we will assess the situation and offer a partial or full refund.
      </p>

      <h2 className="section-heading">7. Privacy Policy</h2>
      <p className="terms-paragraph">
        We value your privacy and handle all personal data in compliance with the UK General Data Protection Regulation (GDPR). Your data will only be used for the purposes of visa application and related services.
      </p>

      <h2 className="section-heading">8. Termination of Services</h2>
      <p className="terms-paragraph">
        We reserve the right to terminate our services if any illegal activity, fraudulent documentation, or non-compliance with these terms is detected.
      </p>

      <h2 className="section-heading">9. Governing Law and Jurisdiction</h2>
      <p className="terms-paragraph">
        These terms and conditions are governed by and construed in accordance with the laws of the United Kingdom. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the UK courts.
      </p>

      <h2 className="section-heading">10. Changes to Terms and Conditions</h2>
      <p className="terms-paragraph">
        INNIT Consultancy reserves the right to modify these terms at any time. Clients will be notified of any significant changes, and continued use of our services will indicate acceptance of the revised terms.
      </p>
    </div>
  );
};

export default TermsAndConditions;
