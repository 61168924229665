import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import './footer.css';

const Footer = () => {
  const navigate = useNavigate(); // Initialize the hook

  // Handler function to navigate programmatically
  

  const handleNavigation = (path) => {
    navigate(path); // Navigate to the path
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0); // Smooth scroll to the top
  };

  return (
    <>
      {/* Add a white/light grey space before the footer */}
      <div className="footer-space"></div>

      <footer className="footer">
        <div className="footer-container">
          {/* Footer Column 1 */}
          <div className="footer-column">
            <h3>UK Visa Consulting</h3>
            <p>
              Connecting individuals to UK visa solutions with expert care and tailored guidance.
            </p>
            <div className="social-icons">
            <button onClick={() => handleNavigation('/join-now')}>
            <FaFacebookF />
                </button>
                
                <button onClick={() => handleNavigation('/join-now')}>
                <FaInstagram />
                </button>

                <button onClick={() => handleNavigation('/join-now')}>
                <FaTwitter />
                </button>

                <button onClick={() => handleNavigation('/join-now')}>
                <FaLinkedinIn />
                </button>
              
            </div>
          </div>

          {/* Footer Column 2 */}
          <div className="footer-column">
            <h3>Company</h3>
            <ul>
              <li>
                <button onClick={() => handleNavigation('/about-us')}>
                  About
                </button>
              </li>
              <li>
                <button onClick={() => handleNavigation('/join-now')}>
                  Features
                </button>
              </li>
              <li>
                <button onClick={() => handleNavigation('/join-now')}>
                  Careers
                </button>
              </li>
            </ul>
          </div>

          {/* Footer Column 3 */}
          <div className="footer-column">
            <h3>Help</h3>
            <ul>
              <li>
                <button onClick={() => handleNavigation('/join-now')}>
                  Support
                </button>
              </li>
              <li>
                <button onClick={() => handleNavigation('/terms-and-conditions')}>
                  Terms & Conditions
                </button>
              </li>
              <li>
                <button onClick={() => handleNavigation('/privacy-policy')}>
                  Privacy Policy
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-bottom">
          <p>© Copyright 2024, All Rights Reserved</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
