import React from "react";
import './AboutUs.css'; // Import the CSS file

const AboutUs = () => {
  return (
    <div className="about-container">
      <h1 className="about-title">INNIT Consultancy Services</h1>
      <p className="about-paragraph">
        At <strong>INNIT Consultancy Services</strong>, we believe in transforming lives by helping individuals unlock opportunities beyond borders. Our mission goes beyond visa processing – we empower people to achieve their dreams and redefine their future with global possibilities.
      </p>

      <p className="about-paragraph">
        Driven by <strong>excellence, integrity, and trust</strong>, we provide immigration and visa services that are personalized, transparent, and effective. Our experienced team of counselors and immigration experts tailors every service to meet your unique aspirations and goals. Whether it’s securing a work visa, pursuing education abroad, migrating with family, or starting a business in a new country, INNIT ensures your journey is smooth and successful.
      </p>

      <h2 className="section-heading">What Makes Us Different</h2>
      <ul className="about-list">
        <li>
          <strong>Client-Centered Approach:</strong> We treat every client as a partner, investing time in understanding their background and goals to offer the best visa solutions.
        </li>
        <li>
          <strong>Proactive Guidance and Support:</strong> We stay ahead of policy changes and immigration trends, providing updated advice and guidance.
        </li>
        <li>
          <strong>Fast and Reliable Service:</strong> With efficient processes, we ensure your application progresses swiftly, minimizing delays and delivering faster results.
        </li>
      </ul>

      <h2 className="section-heading">Building a Future Without Borders</h2>
      <p className="about-paragraph">
        Crossing borders isn’t just a move—it’s a life-changing experience. It brings personal growth, financial stability, and global citizenship. Whether you seek new career opportunities, academic excellence, or business expansion, INNIT Consultancy is here to help you unlock your potential.
      </p>

      <p className="about-paragraph">
        A single move can reshape futures, impact families, and foster cross-cultural exchange. We believe in creating <strong>borderless opportunities</strong> where talent is nurtured and rewarded, regardless of origin.
      </p>

      <h2 className="section-heading">Our Promise</h2>
      <p className="about-paragraph">
        At INNIT Consultancy, our mission is to transform lives. Every visa application is more than just paperwork—it’s a dream taking flight and a future being rewritten. We walk alongside our clients, motivating, guiding, and supporting them at every step of their journey.
      </p>

      <h2 className="section-heading">Why Choose INNIT Consultancy?</h2>
      <ul className="about-list">
        <li>
          <strong>End-to-End Immigration Solutions:</strong> From eligibility assessments to visa filing, we manage everything for a seamless experience.
        </li>
        <li>
          <strong>Personalized Counseling:</strong> We provide career and immigration counseling to empower clients and boost their confidence.
        </li>
        <li>
          <strong>Global Vision, Local Expertise:</strong> With a deep understanding of immigration laws and global trends, we connect clients with worldwide opportunities.
        </li>
        <li>
          <strong>Commitment Beyond Profit:</strong> Success for us is measured by client satisfaction and changed lives—not just numbers on a balance sheet.
        </li>
      </ul>

      <h2 className="section-heading">Join Hands with Us</h2>
      <p className="about-paragraph">
        At INNIT, your dream is our mission. We envision a world where talent knows no boundaries and opportunities are shared across continents. Together, let’s open doors to new possibilities, growth, and success.
      </p>

      <p className="about-paragraph highlight">
        <strong>INNIT Consultancy Services</strong> – Your trusted partner in global success.
      </p>
    </div>
  );
};

export default AboutUs;
