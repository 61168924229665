import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Services from '../components/Services';
// import Team from './components/Team';
// import Testimonials from './components/Testimonials';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import '../styles/styles.css';

function Home() {
  return (
    <>
      {/* <Navbar /> */}
      <Hero />
      <Services />
      <ContactForm />
      {/* <Footer /> */}
      {/* <Services />
      <Team />
      <Testimonials />
      <ContactForm /> */}
    </>
  );
}

export default Home;
